<template>
	<div class="pilotageFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.pilotage.portname')" :value="getPortnameValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.pilotage.portcallnumber')" :value="getPortcallnumberValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.pilotage.nummovement')" :value="getMovementNumValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.pilotage.vesselname')" :value="getVesselnameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'pilotageFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'pilotage'
		};
	},
	computed: {
		getPortnameValue() {
			return this.model && this.model.portname ? this.model.portname : '-';
		},
		getPortcallnumberValue() {
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '-';
		},
		getMovementNumValue() {
			return this.model && this.model.nummovement ? this.model.nummovement : '-';
		},
		getVesselnameValue() {
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		}
	}
};
</script>
